<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <BaseButton
    shape="curved"
    color="primary"
    to="/auth/login-1"
  >
    {{ t('signIn') }}
  </BaseButton>
</template>

<i18n lang="json">
{
  "en": {
    "signIn": "Sign in"
  },
  "nl": {
    "signIn": "Inloggen"
  }
}
</i18n>
